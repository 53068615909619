import { injectable } from 'inversify-props';
import dayjs from 'dayjs';
import BaseService from '../base-service';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import CallCenterFinishCallModel from '@/models/crm/call-center-finish-call.model';
import CallCenterOverviewModel from '@/models/crm/call-center-overview.model';
import { PaginationModel } from '@/models/pagination.model';
import { RequestConfig } from '@/interfaces/request-config.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import BasicCallCenterResponse from '@/models/crm/basic-call-center-response';

interface IPhoneCallParams {
  phone: string;
  agentId: string;
}

@injectable()
export default class CallCenterCallService extends BaseService {
  // Overview
  async getOverview(): Promise<CallCenterOverviewModel[]> {
    return (await this.httpService.get(
      CallCenterOverviewModel,
      `${this.getApiPath()}/crm/call-center/overview`,
    )) as CallCenterOverviewModel[];
  }

  // Filas
  async notifyQueue(queueItem: CallCenterCallModel): Promise<CallCenterCallModel> {
    return (await this.httpService.post(
      CallCenterCallModel,
      `${this.getApiPath()}/crm/call-center/queue`,
      queueItem,
    )) as CallCenterCallModel;
  }

  async attachContact(call: CallCenterCallModel): Promise<CallCenterCallModel> {
    const payload: IKeyValue<string | number> = {
      tipo: call.tipo,
      contatoId: call.contato.id,
    };

    if (call.tipo === ConversationTypeEnum.Prospect) {
      payload.prospectId = parseInt(call.prospect?.codProspect, 10);
    } else {
      payload.cnpj = call.cnpj;
    }

    return (await this.httpService.post(
      CallCenterCallModel,
      `${this.getApiPath()}/crm/call-center/${call.id}/attach-contact`,
      payload,
    )) as CallCenterCallModel;
  }

  // Chamadas
  async getCallToQualify(): Promise<CallCenterCallModel> {
    return (await this.httpService.get(
      CallCenterCallModel,
      `${this.getApiPath()}/crm/call-center/qualify`,
    )) as CallCenterCallModel;
  }

  async finishCall(callId: number, item: CallCenterFinishCallModel): Promise<void> {
    const paylod: any = {
      descricao: item.descricao,
      resultado: item.resultado,
      origem: null,
      grupoArea: null,
      tipoHistorico: null,
    };

    if (item.origem) {
      paylod.origem = {
        id: item.origem.id,
      };
    }

    if (item.tipoHistorico) {
      paylod.tipoHistorico = {
        id: item.tipoHistorico.id,
      };
    }

    if (item.grupoArea) {
      paylod.grupoArea = {
        id: item.grupoArea.id,
      };
    }

    return (await this.httpService.post(
      CallCenterFinishCallModel,
      `${this.getApiPath()}/crm/call-center/${callId}/finish`,
      paylod,
    )) as any;
  }

  async queryCalls(
    page: number,
    limit: number,
    filter: unknown,
    orderBy?: string,
  ): Promise<PaginationModel<CallCenterCallModel>> {
    const config: RequestConfig = {
      params: {
        page,
        limit,
      },
    };

    if (orderBy) {
      config.params.orderBy = orderBy;
    }

    return (await this.httpService.post(
      PaginationModel,
      `${this.getApiPath()}/crm/call-center/query`,
      filter,
      config,
    )) as PaginationModel<CallCenterCallModel>;
  }

  async getById(id: number): Promise<CallCenterCallModel> {
    return (await this.httpService.get(
      CallCenterCallModel,
      `${this.getApiPath()}/crm/call-center/${id}`,
    )) as CallCenterCallModel;
  }

  async getAllByProtocol(protocol: string): Promise<CallCenterCallModel[]> {
    return (await this.httpService.get(
      CallCenterCallModel,
      `${this.getApiPath()}/crm/call-center/protocol/${protocol}`,
    )) as CallCenterCallModel[];
  }

  // Static methods
  static generateCallReportExportFilename(date: Date): string {
    return `Ligacoes_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  async makePhoneCall({ phone, agentId }: IPhoneCallParams): Promise<BasicCallCenterResponse> {
    const url = `${this.getApiPath()}/crm/call-center/call?agentId=${agentId}&phoneNumber=${encodeURIComponent(phone)}`;
    return (await this.httpService.get(BasicCallCenterResponse, url)) as BasicCallCenterResponse;
  }
}
