import { Type, Exclude, plainToClass } from 'class-transformer';
import CityModel from '@/models/city.model';
import ClientModel from '@/models/crm/client.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import StateModel from '@/models/state.model';
import RepresentativeModel from '@/models/crm/representative.model';
import LeadModel from './lead.model';
import ContactChannelModel from './contact-channel.model';

export default class ProspectModel {
  codProspect!: string;

  uuid!: string;

  cnpj!: string;

  razaoSocial!: string;

  fantasia!: string;

  flagCliente!: number;

  flagExcluido!: number;

  contato!: string;

  email!: string;

  emailNFE!: string;

  facebook!: string;

  instagram!: string;

  consumidorFinal!: number;

  ddd!: string;

  ddi!: string;

  exibirfv!: number;

  telefone!: string;

  celular!: string;

  situacao!: number;

  @Type(() => CityModel)
  cidade!: CityModel;

  @Exclude()
  estado!: StateModel;

  @Type(() => Date)
  dataAtualizacao!: Date;

  alteracaoUsuarioId!: number;

  codEmpresa!: string;

  @Type(() => Date)
  dataCadastro!: Date;

  inclusaoUsuarioId!: number;

  inscricaoEstadual!: string;

  suframa!: string;

  cep!: string;

  endBairro!: string;

  endComplemento!: string;

  endereco!: string;

  endNumero!: string;

  codRepres!: string;

  nomeRepres!: string;

  observacao!: string;

  particularidades!: string;

  @Type(() => LeadModel)
  lead!: LeadModel;

  @Type(() => ContactChannelModel)
  formaCaptacao!: ContactChannelModel;

  origemSintegra = false;

  origemCadastro = 1;

  atendenteChave!: number;

  atendenteResponsavel?: string;

  nomeAtendenteResponsavel?: string;

  prospectCodCliente?: string | null;

  get clientModel(): ClientModel {
    const model = new ClientModel();
    model.type = ClientTypeEnum.Prospect;
    model.celular = this.celular;
    model.cidade = this.cidade?.nome;
    model.cnpjCpf = (this.cnpj || '').replace(/\D/g, '');
    model.codCliente = this.codProspect;
    model.dataCadastro = this.dataCadastro;
    model.email = this.email;
    model.emailNFE = this.emailNFE;
    model.endBairro = this.endBairro;
    model.endCEP = this.cep;
    model.endComplemento = this.endComplemento;
    model.endNumero = this.endNumero;
    model.endRua = this.endereco;
    model.estado = this.cidade?.siglaUF;
    model.inscEstadual = this.inscricaoEstadual;
    model.nome = this.razaoSocial;
    model.nomeFantasia = this.fantasia;
    model.situacao = this.clientStatus;
    model.telefone = this.telefone;
    model.tipo = 'Prospect';
    model.observacao = this.observacao;
    model.codRepres = this.codRepres;
    model.nomeRepres = this.nomeRepres;
    model.particularidades = this.particularidades;
    model.formaCaptacao = this.formaCaptacao;
    model.lead = this.lead;
    model.prospectId = Number(this.codProspect);
    model.flagCliente = this.flagCliente;
    model.atendenteChave = this.atendenteChave;
    model.prospectCodCliente = this.prospectCodCliente;
    model.origemCadastro = this.origemCadastro;
    model.instagram = this.instagram;
    model.facebook = this.facebook;

    return model;
  }

  get clientStatus(): ClientStatusEnum | undefined {
    switch (this.situacao) {
      case 1:
        return ClientStatusEnum.Active;
      case 0:
        return ClientStatusEnum.Inactive;
      case 98:
        return ClientStatusEnum.ConversionFailed;
      case 99:
        return ClientStatusEnum.Converted;
      default:
        return undefined;
    }
  }

  get representante(): RepresentativeModel {
    return plainToClass(RepresentativeModel, {
      codigo: this.codRepres,
      nome: this.nomeRepres,
    });
  }

  set representante(representative: RepresentativeModel) {
    this.codRepres = representative.codigo;
    this.nomeRepres = representative.nome;
  }
}
