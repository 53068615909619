import { VueConstructor } from 'vue';
import { ICallCenter } from '@/interfaces/call-center/call-center.interface';

// -----------------------------------------------------------------------------------------------
// Você pode encontrar mais informações a respeito de como realizar a implementação no link abaixo
// https://stackoverflowteams.com/c/geovendas/questions/783/784
// -----------------------------------------------------------------------------------------------

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Vue {
    readonly $callCenter: ICallCenter;
  }
}

const plugin = {
  install: (vue: VueConstructor, patnerActions: ICallCenter): void => {
    const vueInstance = vue;
    const partnerInstance = patnerActions;

    vueInstance.prototype.$callCenter = {
      subscribe: partnerInstance.subscribe,
      configure: partnerInstance.configure,
      login: partnerInstance.login,
      makeAPause: partnerInstance.makeAPause,
      logout: partnerInstance.logout,
      available: partnerInstance.available,
      finishCall: partnerInstance.finishCall,
      decline: partnerInstance.decline,
      return: partnerInstance.return,
      transfer: partnerInstance.transfer,
      qualify: partnerInstance.qualify,
      getUserLogged: partnerInstance.getUserLogged,
      makePhoneCall: partnerInstance.makePhoneCall,
      updateCallInformation: partnerInstance.updateCallInformation,
    };
  },
};

export default plugin;
