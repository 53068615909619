import { inject, injectable } from 'inversify-props';
import axios, { AxiosInstance } from 'axios';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import SessionService from '@/services/session.service';

@injectable()
export default class CloudTalkService {
  private axios: AxiosInstance;

  @inject(InjectionIdEnum.SessionService)
  protected sessionService!: SessionService;

  constructor() {
    this.axios = axios.create();
  }
}
