import { Transform, Type } from 'class-transformer';
import ContactTypeModel from '@/models/crm/contact-type.model';
import { DateTransform } from '@/utils/value-transformers/date.transform';
import OriginModel from './origin.model';

export default class ContactModel {
  uuid!: string;

  alteracaoData!: string;

  alteracaoUsuarioId!: number;

  cnpj!: string;

  cnpjFormatado!: string;

  @Transform(DateTransform)
  dataAniversario!: Date;

  email!: string;

  flagInativo!: number;

  id!: number;

  inclusaoData!: string;

  inclusaoUsuarioId!: number;

  nome!: string;

  telefone!: string;

  whatsapp!: string;

  tipo!: number;

  tipoCliente!: string;

  idProspect?: number;

  nomeCliente?: string;

  @Type(() => ContactTypeModel)
  tipoContato!: ContactTypeModel;

  tiposContato!: ContactTypeModel[] | null;

  melhorHoraInicio!: string;

  melhorHoraFim!: string;

  canaisContato!: OriginModel[];

  respostaOptin!: string;

  get flagAtivo(): number {
    return this.flagInativo ? 0 : 1;
  }

  set flagAtivo(value: number) {
    this.flagInativo = value ? 0 : 1;
  }
}
