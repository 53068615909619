import Vue from 'vue';
import CallCenterUserService from '@/services/crm/call-center-user.service';
import CallCenterCallService from '@/services/crm/call-center-call.service';
import CallCenterUserLoginModel from '@/models/crm/call-center-user-login.model';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import { CallCenterActionEnum } from '@/enums/crm/call-center-action.enum';
import { CallCenterEventEnum } from '@/enums/crm/call-center-event.enum';
import CallCenterCallModel from '@/models/crm/call-center-call.model';
import { CallCenterCallStatusEnum } from '@/enums/crm/call-center-call-status.enum';
import CallCenterUserBreakModel from '@/models/crm/call-center-user-break.model';
import CallCenterQueueInformationModel from '@/models/crm/call-center-queue-information.model';
import { CallCenterCallTypeEnum } from '@/enums/crm/call-center-call-type.enum';
import { IWebsocketCallCenter } from '@/interfaces/call-center/websocket-call-center.interface';

export default class EoxCallCenter implements IWebsocketCallCenter {
  protected callCenterUserService: CallCenterUserService = new CallCenterUserService();

  protected callCenterCallService: CallCenterCallService = new CallCenterCallService();

  protected showNotification = false;

  protected websocketInstance: WebSocket | null = null;

  protected userPartnerInstance: CallCenterUserLoginModel | null = null;

  protected callCenterUser: CallCenterUserModel | null = null;

  protected userPartner: CallCenterUserLoginModel | null = null;

  protected websocketConnectionString = '';

  protected extensionUserCallCenter = '';

  protected idUserUserCallCenter = '';

  protected unitIdentifierCallCenter = '';

  protected makePhoneCallNumber = '';

  public subscribe: Vue;

  constructor() {
    this.subscribe = new Vue();
  }

  // Evento reponsavel por enviar as solicitações para o websocket do parceiro
  sendMessage = (msg: any) => {
    if (this.websocketInstance != null) {
      this.websocketInstance.send(JSON.stringify(msg));
    } else {
      throw new Error('Conexão websocket callcenter não estabelecida.');
    }
  };

  // Salva a informação do usuario Call Center Geovendas
  private setCallCenterUser = (user: CallCenterUserModel | null) => {
    this.callCenterUser = user;
  };

  // Obtem o usuario Call Center Geovendas salvo
  getUserLogged = (): CallCenterUserModel | null => this.callCenterUser;

  // Salva o usuario do partner
  private setUserPartner = (user: CallCenterUserLoginModel | null) => {
    this.userPartnerInstance = user;
    if (user != null) {
      localStorage.setItem('userPartnerData', JSON.stringify(user));
    } else {
      localStorage.removeItem('userPartnerData');
    }
  };

  // Obtem o usuario do partner salvo
  private getUserPartnerInstance = (): CallCenterUserLoginModel | null => {
    if (this.userPartnerInstance != null) {
      return this.userPartnerInstance as CallCenterUserLoginModel;
    }

    const dataStorage = localStorage.getItem('userPartnerData');
    if (dataStorage && dataStorage != null) {
      return JSON.parse(dataStorage);
    }

    return null;
  };

  // Cria a postnotification de uma nova chamada.
  private createPostNotification = () => {
    if (!('Notification' in window)) {
      console.warn('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      const notification = new Notification(
        'VOCÊ ESTÁ RECEBENDO UMA LIGAÇÃO! Acesse o painel de relacionamento para ter acesso às informações da ligação.',
      );

      notification.onclick = () => {
        window.focus();
      };
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const notification = new Notification('VOCÊ ESTÁ RECEBENDO UMA LIGAÇÃO', {
            data: 'Acesse o painel de relacionamento para ter acesso às informações da ligação.',
          });
        }
      });
    }
  };

  // Evento responsavel por fazer o login no parceiro.
  // Após ser confirmado o login no parceiro recebemos uma notificação via websocket
  // e então fazemos o login no Geovendas
  login = (user: CallCenterUserLoginModel, automatic = false): void => {
    const isValidExtension = user.ramal === this.extensionUserCallCenter;
    const isValidCode = user.id === this.idUserUserCallCenter;

    if (isValidExtension && isValidCode) {
      if (this.websocketInstance == null && this.reopenConection != null && this.websocketConnectionString) {
        this.setUserPartner(user);
        this.reopenConection(
          this.websocketConnectionString,
          this.extensionUserCallCenter,
          this.idUserUserCallCenter,
          this.showNotification,
          this.unitIdentifierCallCenter,
        );
      } else if (this.websocketInstance != null) {
        this.setUserPartner(user);

        const msg = {
          action: CallCenterActionEnum.Login,
          data: {
            id: user.id,
            ramal: user.ramal,
            senha: user.password,
            dac: '',
          },
        };

        this.sendMessage(msg);
      } else {
        throw new Error('Endereço websocket do callcenter não fornecido.');
      }
    } else {
      this.setUserPartner(null);
      if (!automatic) {
        this.subscribe.$emit(
          CallCenterEventEnum.Error,
          'Não foi possível realizar o Login pois o ramal e agente registrados não estão vinculados ao seu usuário.',
        );
      }
    }
  };

  // Envia uma solicitação de pausa para o parceiro.
  // Recebemos a confirmação via evento no websocket.
  makeAPause = (reason: number): void => {
    const msg = {
      action: CallCenterActionEnum.Break,
      data: {
        id_motivo: reason.toString(),
      },
    };

    this.sendMessage(msg);
  };

  // Informa o parceiro que o usuário está disponivel
  // Recebemos a confimação via websocket.
  available = (): void => {
    const msg = {
      action: CallCenterActionEnum.Avaiable,
      data: {},
    };

    this.sendMessage(msg);
  };

  // Informa ao parceiro o logout do usuário.
  // Recebemos a confirmação da ação via websocket.
  logout = (): void => {
    this.userPartner = this.getUserPartnerInstance();
    if (this.userPartner) {
      this.callCenterUserService.userLogout(this.userPartner.id);

      const msg = {
        action: CallCenterActionEnum.Logout,
        data: {},
      };

      this.sendMessage(msg);
    }
  };

  // Recusa uma ligação e envia a solicitação para o partner
  // Recebe a confirmação via websocket.
  decline = (call: CallCenterCallModel): void => {
    const msg = {
      action: CallCenterActionEnum.Decline,
      data: {
        id_chamada: call.idChamada,
      },
    };

    this.sendMessage(msg);
  };

  // Solicita ao parceiro realizar a proxíma chamada aguardando retorno.
  // Recebemos as confirmações via websocket.
  return = (): void => {
    const msg = {
      action: CallCenterActionEnum.Return,
      data: {},
    };

    this.sendMessage(msg);
  };

  // Evento responsavel por chamar a modal de finalização de chamada
  finishCall = (callData: CallCenterCallModel, transferUser: CallCenterUserModel | null): void => {
    const finishedOutside = false;
    this.subscribe.$emit(CallCenterEventEnum.FinishCall, callData, transferUser, finishedOutside);
  };

  // Envia uma transferencia de chamada para o parceiro.
  // Primeiro, notificamos o geovenda que a chamada está sendo transferida
  // Em seguida nós enviamos a solicitação para o parceiro.
  transfer = (callData: CallCenterCallModel, user: CallCenterUserModel): void => {
    const call: CallCenterCallModel = new CallCenterCallModel();
    call.idChamada = callData.idChamada;
    call.uuid = callData.uuid;
    call.numeroContato = callData.numeroContato;
    call.statusLigacao = CallCenterCallStatusEnum.Transfer;
    call.dataTransferencia = new Date();
    call.tipoLigacao = callData.tipoLigacao;

    const msg = {
      action: CallCenterActionEnum.Transfer,
      data: {
        id_chamada: call.idChamada,
        telefone: user.ramal,
      },
    };

    this.callCenterCallService.notifyQueue(call).then(() => {
      this.sendMessage(msg);
    });
  };

  // Evento responsavel de enviar a qualificação da chamada para o parceiro.
  // Recebemos a confirmação via websocket.
  qualify = (callId: string): void => {
    const msg = {
      action: CallCenterActionEnum.SendQualification,
      data: {
        id_qualificacao_pendente: callId,
        qualificacao: 'Qualificação interna Geovendas.',
      },
    };

    this.sendMessage(msg);
  };

  // Realiza uma chamada ativa apartir do geovendas.
  // Recemos as informações da chamada via websocket.
  makePhoneCall = (phone: string): void => {
    const userLogged = this.getUserLogged();
    if (userLogged != null) {
      const msg = {
        action: CallCenterActionEnum.CallTheNumber,
        data: {
          telefone: phone,
        },
      };

      this.makePhoneCallNumber = phone;

      this.sendMessage(msg);
    }
  };

  // Metodo responsavel por atualizar as informações da chamada quando necessário
  updateCallInformation = (callData: CallCenterCallModel): void => {
    if (callData) {
      this.subscribe.$emit(CallCenterEventEnum.CallInProgress, callData, false);
    }
  };

  // Metodo reponsável por configurar o websocket
  // Metodo reponsávl por controlar toda a comunicação com o parceiro
  // onmessage: metodo que recebe as informações do parceiro e realiza o comportamento necessario
  configure = (
    websocket: string,
    extensionId: string,
    idUser: string,
    notification: boolean,
    unitIdentifier: string,
  ): void => {
    this.websocketConnectionString = websocket;
    this.showNotification = notification;
    this.extensionUserCallCenter = extensionId;
    this.idUserUserCallCenter = idUser;
    this.unitIdentifierCallCenter = unitIdentifier;
    this.websocketInstance = new WebSocket(websocket);

    this.websocketInstance.onopen = () => {
      console.info('Conexão com websocket callcenter estabelecida.');

      this.userPartner = this.getUserPartnerInstance();
      if (this.userPartner != null) {
        this.login(this.userPartner, true);
      }
    };

    this.websocketInstance.onerror = () => {
      console.info('Erro ao estabelecer conexão com websocket callcenter.');
    };

    this.websocketInstance.onclose = (obs: any) => {
      this.websocketInstance = null;
      console.info('Conexao derrubada/caiu.', obs);
    };

    const { unitIdentifierCallCenter } = this;
    this.websocketInstance.onmessage = (event: any) => {
      const eventData = JSON.parse(event.data);
      switch (eventData.action) {
        case CallCenterActionEnum.Login: {
          this.userPartner = this.getUserPartnerInstance();
          if (this.userPartner) {
            this.callCenterUserService
              .userLogin(this.userPartner.id)
              .then((data: CallCenterUserModel) => {
                this.setCallCenterUser(data);
                this.subscribe.$emit(CallCenterEventEnum.UserLogged, data);
              })
              .catch(this.logout);
          }

          break;
        }
        case CallCenterActionEnum.Logout: {
          this.userPartner = this.getUserPartnerInstance();
          if (this.userPartner) {
            this.callCenterUserService.userLogout(this.userPartner.id).then(() => {
              this.setCallCenterUser(null);
              this.setUserPartner(null);
              try {
                this.websocketInstance?.close();
                this.websocketInstance = null;
              } catch (e) {
                console.info('erro ao fechar conexao');
              }
              this.subscribe.$emit(CallCenterEventEnum.UserLogout);
            });
          }

          break;
        }
        case CallCenterActionEnum.Break: {
          this.userPartner = this.getUserPartnerInstance();
          if (this.userPartner) {
            const breakReason: CallCenterUserBreakModel = {
              id: eventData.data.id_motivo,
              descricao: eventData.data.motivo,
              dataHora: new Date(eventData.data.hora).toISOString(),
            };

            this.callCenterUserService
              .userBreak(this.userPartner.id, breakReason)
              .then(() => {
                this.subscribe.$emit(CallCenterEventEnum.Break, breakReason);
              })
              .catch(this.logout);
          }

          break;
        }
        case CallCenterActionEnum.Avaiable: {
          this.userPartner = this.getUserPartnerInstance();
          if (this.userPartner) {
            this.callCenterUserService
              .userAvaiable(this.userPartner.id)
              .then(() => {
                this.subscribe.$emit(CallCenterEventEnum.Avaiable);
              })
              .catch(this.logout);
          }

          break;
        }
        case CallCenterActionEnum.Queue: {
          const queueInformationData: CallCenterQueueInformationModel = eventData.data;
          if (queueInformationData != null) {
            this.subscribe.$emit(CallCenterEventEnum.Queue, queueInformationData);
          }

          break;
        }
        case CallCenterActionEnum.Pending: {
          const callBackInformationData: CallCenterQueueInformationModel = eventData.data;
          if (callBackInformationData != null) {
            this.subscribe.$emit(CallCenterEventEnum.Pending, callBackInformationData);
          }

          break;
        }
        case CallCenterActionEnum.Call: {
          const callData = eventData.data;

          if (callData.dnis !== unitIdentifierCallCenter) {
            return;
          }

          switch (callData.estado) {
            case 'Tentando': {
              const isActive = callData.direcao === 'realizada';
              const call: CallCenterCallModel = new CallCenterCallModel();
              call.idChamada = callData.id_chamada;
              call.uuid = callData.uuid;
              call.numeroContato = callData.telefone;
              call.dataInclusao = new Date(callData.hora);
              call.dataFila = new Date(callData.hora);
              call.statusLigacao = CallCenterCallStatusEnum.NewCall;
              call.tipoLigacao = isActive ? CallCenterCallTypeEnum.Active : CallCenterCallTypeEnum.Receptive;

              if (!isActive && this.showNotification) {
                console.log('TENTA GERAR A NOTIFICACAO');
                this.createPostNotification();
              }

              this.subscribe.$emit(CallCenterEventEnum.NewCall, call, true);

              this.callCenterCallService.notifyQueue(call).then((newCallData: CallCenterCallModel) => {
                this.subscribe.$emit(CallCenterEventEnum.NewCall, newCallData, false);
              });
              break;
            }
            case 'Atendido': {
              const isActive = callData.direcao === 'realizada';
              const call: CallCenterCallModel = new CallCenterCallModel();
              call.idChamada = callData.id_chamada;
              call.uuid = callData.uuid;
              call.numeroContato = callData.telefone;
              call.dataAtendimento = new Date(callData.hora);
              call.statusLigacao = CallCenterCallStatusEnum.NewCall;
              call.tipoLigacao = isActive ? CallCenterCallTypeEnum.Active : CallCenterCallTypeEnum.Receptive;

              this.subscribe.$emit(CallCenterEventEnum.CallInProgress, call, true);

              this.callCenterCallService.notifyQueue(call).then((newCallData: CallCenterCallModel) => {
                this.subscribe.$emit(CallCenterEventEnum.CallInProgress, newCallData, false);
              });

              break;
            }
            case 'Finalizado': {
              const call: CallCenterCallModel = new CallCenterCallModel();
              call.idChamada = callData.id_chamada;
              call.uuid = callData.uuid;
              call.dataFinalizacao = new Date(callData.hora);
              call.statusLigacao = CallCenterCallStatusEnum.Finish;

              this.makePhoneCallNumber = '';

              this.callCenterCallService.notifyQueue(call).then((newCallData: CallCenterCallModel) => {
                this.subscribe.$emit(CallCenterEventEnum.MissedCall);
                if (callData.gravacao != null) {
                  if (!newCallData.tipoHistorico) {
                    const finishedOutside = true;
                    this.subscribe.$emit(CallCenterEventEnum.FinishCall, newCallData, null, finishedOutside);
                  } else {
                    this.qualify(call.idChamada);
                  }
                }
              });

              break;
            }
            default: {
              break;
            }
          }

          break;
        }
        case CallCenterActionEnum.AwaitingQualification: {
          break;
        }
        default: {
          if (eventData.erro && eventData.msg) {
            this.subscribe.$emit(CallCenterEventEnum.Error, eventData.msg);
          }

          break;
        }
      }
    };
  };

  // Realiza a reconexao após ser efeutado logout e tentar logar novamente.
  reopenConection = this.configure;
}
